import consumer from "./consumer"

consumer.subscriptions.create({channel: "GoGuildChannel", go_guild_id: go_guild_id, ws_token: ws_token}, {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {

        if (data.ws_type === "reload_research") {
            if ($(".research_tree").length > 0)
                update_research_small(data.ids)
        } else if (data.ws_type === "new_upgrade_count") {
            let selector = $(".ready_to_research_count[data-building-type='"+data.building_type+"']").html(data.ready_to_upgrade_count)
            if(data.ready_to_upgrade_count > 0) {
                selector.removeClass("d-none")
            }else{
                selector.addClass("d-none")
            }
        } else if (data.ws_type === "new_motd") {
            motd = data.motd
            $(".motd").replaceWith(data.motd_body)
            toastr_wrapper({
                "toast_type": "success",
                "title": "New Guild Message of The Day.",
                "text": data.motd,
                timeOut: 0
            })
        } else if (data.ws_type === "new_bp_balance") {

            if ($("[data-bp-needed]").length > 0){
                let val = parseInt($("[data-bp-needed]").data("bp-needed"))
                if(val > parseFloat(data.balance))
                    $("[data-bp-needed]").addClass("disabled")
                else
                    $("[data-bp-needed]").removeClass("disabled")
            }
            if ($(".replace_bp").length > 0){
                $(".replace_bp").html(data.balance)
            }

        } else {

            if ($(".guild_chat_wrapper").length > 0) {
                $(".guild_chat_wrapper").prepend(data.text)
                $(".game_menu .g_chat").scrollTop($(".game_menu .guild_chat_wrapper").innerHeight())
                $.ajax({
                    url: "/guilds/message", // ?done=true
                    method: "GET",
                    success: function (data) {
                        $(".new_guild_messages").html(data)
                        if(data > 0) {
                            $(".new_guild_messages").closest(".single_navigation").removeClass("new_messages")
                            $(".new_guild_messages").removeClass("d-none")
                        }
                    }
                })
            } else {

                $.ajax({
                    url: "/guilds/message",
                    method: "GET",
                    success: function (data) {
                        $(".new_guild_messages").html(data)
                        update_ux_from_server("bottom_nav")
                    }
                })
            }
        }
    }
});
